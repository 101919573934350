import proxy from "api/proxy";

export const getData = async (value) => {
  const { data, error } = await proxy.search(
    "associate",
    [["party.id_number", "=", value]],
    [
      "party.name",
      "party.id_number",
      "associate_profile.name",
      "associate_profile.description",
      "payment_until",
      "member_ship.name",
      "member_ship.total_days",
      "guests.party.name",
      "guests.party.id_number",
      "guests.associate_profile",
      "guests.associate_profile.name",
      "guests.associate.associate_profile.name",
      "guests.associate.party.name",
      "associate.payment_until",
      "associate.party.name",
      "associate.party.id_number",
    ],
  );

  if (data.length !== 0) {
    return { data: data[0], error: null };
  } else {
    return { data: null, error };
  }
};
