import React from "react";
import ReactDataSheet from "react-datasheet";
import date from "date-and-time";
import "react-datasheet/lib/react-datasheet.css";

import dates from "tools/dates";
import funcs from "tools/functions";
import { classNames } from "tools/ui";
import "./Sheet.css";

function Sheet(props) {
  const { records, onSave } = props;

  function validateData(change) {
    if (!change.cell.id) {
      return;
    }
    const value = change.value;
    let _value = value.toString().replace(/\./g, "");
    if (isNaN(_value)) return;
    return true;
  }

  const onDataChange = async (changes) => {
    const grid = records.map((row) => [...row]);
    for (let ch of changes) {
      if (!validateData(ch)) return;
      const dataSave = {
        id: ch.cell.id,
        data: ch.cell.data,
        unit_price: ch.value,
      };
      if (onSave) {
        onSave(dataSave);
      }
    }
    changes.forEach(({ row, col, value }) => {
      records[row][col] = { ...grid[row][col], value };
    });
  };

  const valueRenderer = (cell) => {
    let value = cell.value;
    switch (cell.style) {
      case "sheet-cell-price":
        value = funcs.fmtMoneyLocal(value, 0);
        break;
      case "sheet-head-date":
        const valueJs = dates.getTrytonDate2Js(value);
        const fmtValueDay = date.format(valueJs, "DD");
        const fmtValueWeekday = date.format(valueJs, "ddd");
        value = `${fmtValueWeekday} ${fmtValueDay}`;
        break;
      default:
        break;
    }
    return value;
  };

  const cellRenderer = (propsCell) => {
    const { cell, style, children, ...rest } = propsCell;
    let colSpan = cell.colSpan || 1;
    let readOnly = true;
    let _display = "";
    let _textAlign = "!text-center";
    let _fontSize = "!text-sm";
    let _width = "w-3";
    let _fontWeight = "";
    let _minWidth = "!min-w-[4rem]";
    let _color = "!text-black";
    let _backgroundColor = "!bg-white";
    let _height = "!h-10";
    let _padding = "!px-1.5";
    let _border = "";
    let _verticalAlign = "!align-middle";
    let _fontFamily = "";
    let _pointer = "";
    let baseClassName = rest.className;

    let cellValue = { ...children };
    let { cell: dataCell } = cellValue.props;

    switch (cell.style) {
      case "sheet-cell-price":
        _backgroundColor = "!bg-white";
        _textAlign = "!text-right";
        readOnly = cell.readOnly;
        break;
      case "sheet-cell-plist":
        _backgroundColor = "!bg-zinc-100";
        _textAlign = "!text-left";
        _color = "!text-sky-700";
        break;
      case "sheet-head-date":
        _backgroundColor = "!bg-zinc-100";
        _height = "!h-[2.5rem]";
        _minWidth = "!min-w-[5rem]";
        _color = "!text-gray-700";
        _fontWeight = "!font-light";
        _border = "!border-y-0";
        break;
      case "sheet-row-blank":
        _backgroundColor = "!bg-white";
        _border = "!border-none";
        _height = "!max-h-[4rem]";
        _pointer = "pointer-events: none !important";
        break;
      case "sheet-head-month":
        _fontWeight = "!font-bold";
        _backgroundColor = "!bg-zinc-100";
        _color = "!text-gray-600";
        _textAlign = "!text-left !pl-3";
        _height = "!h-[2rem]";
        _border = "!border-y-0";
        break;
      case "sheet-head-accommodation":
        _backgroundColor = "!bg-amber-200";
        _fontWeight = "!font-bold";
        _minWidth = "!min-w-[14rem]";
        _textAlign = "!text-left";
        _color = "!text-gray-700";
        break;
      case "sheet-head-unit-price":
        _backgroundColor = "!bg-amber-200";
        _fontWeight = "!font-bold";
        _minWidth = "!min-w-[14rem]";
        _textAlign = "!text-center";
        _color = "!text-gray-700";
        break;
    }

    dataCell.readOnly = readOnly;

    rest.className = classNames(
      baseClassName,
      _width,
      _display,
      _height,
      _minWidth,
      _padding,
      _textAlign,
      _backgroundColor,
      _verticalAlign,
      _color,
      _border,
      _fontWeight,
      _fontSize,
      _fontFamily,
      _pointer,
    );

    // This lines fix warning in module reactDataSheet
    rest.updated = "false";
    rest.editing = "false";
    delete rest.attributesRenderer;

    return (
      <td {...rest} style={style} colSpan={colSpan} readOnly={readOnly}>
        {cellValue}
      </td>
    );
  };

  return (
    <div className="overflow-x-scroll">
      <ReactDataSheet
        id="hotel-calendar-datasheet"
        cellRenderer={cellRenderer}
        valueRenderer={valueRenderer}
        data={records}
        overflow="wrap"
        onCellsChanged={(changes) => onDataChange(changes)}
      />
    </div>
  );
}

export default Sheet;
