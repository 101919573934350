import React from "react";

import { classNames } from "tools/ui";

function CardSectionRow({ content1, content2, style }) {
  const base1 = "text-sm";
  const base2 = "text-sm";
  return (
    <div
      id="chart-card-section-row"
      className="flex columns-2 pt-0 justify-between"
    >
      <div className={classNames(base1, style)}>{content1}</div>
      <div className={classNames(base2, style)}>{content2}</div>
    </div>
  );
}

export default CardSectionRow;
