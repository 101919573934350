// Maintenance model
import store from "store";
import date from "date-and-time";

import dates from "tools/dates";
import tools from "tools/common";
import helper from "./calendar/helper";
import modelGuest from "./ModelGuest";
import modelCharge from "./ModelCharge";
import proxy from "api/proxy";
import { useWizardStore } from "store/wizardStore";
import funcs from "tools/functions";

const searchRooms = async () => {
  const session = store.get("ctxSession");
  const rolOwner = await helper.getOwnerUser();
  let dom = [];
  if (rolOwner) {
    store.set("owner", true);
    dom.push(["authorized_users", "in", [session.user]]);
  }
  return dom;
};

const getName = (record) => {
  return record.number;
};

const withChangeDate = (record, store) => {
  const { start_date, end_date } = record;
  if (start_date && end_date) {
    const days = getDays(start_date, end_date);
    record.total_days = days;
  }
};

function getDays(arrival, departure) {
  let arrival_date = arrival;
  let departure_date = departure;
  if (typeof arrival === "string") {
    arrival_date = dates.getTrytonDate2Js(arrival);
  }
  if (typeof departure_date === "string") {
    departure_date = dates.getTrytonDate2Js(departure);
  }
  return date.subtract(departure_date, arrival_date).toDays();
}

// const getFilters = () => {
//   const session = store.get("ctxSession");
//   return {
//     today: `[
//       ('sale_date', '=', '${today}'),
//       ('create_uid', '=', ${session.user}),
//     ]`,
//   };
// };

function confirmVisible(name, record) {
  if (record.id > 0) {
    return false;
  }
  return true;
}

async function confirmMethod() {
  const { store: mant, reload, setCtx } = useWizardStore.getState();
  const _ctxView = getView();
  setCtx({ ctxView: _ctxView, model: "hotel.maintenance" });
  mant.register_date = dates.dateToday();
  mant.state = "draft";
  delete mant.total_days;
  let _mant = funcs.recToTryton({ ...mant });
  const { data, error } = await proxy.saveQuery({
    model: "hotel.maintenance",
    storeRec: _mant,
  });
  const args = { model: "hotel.maintenance", method: "confirm", ids: data };
  await proxy.buttonMethod(args);
}

// function chargesVisible(record) {
//   if (!record || !record.number) {
//     return false;
//   }
//   return true;
// }

const recReadOnly = (record) => {
  if (record.id > 0) {
    return true;
  }
  return false;
};

const CRITICALITY = [
  { id: "low", name: "low" },
  { id: "important", name: "important" },
  { id: "urgent", name: "urgent" },
];

// const STATES = [
//   { id: "draft", name: "draft" },
//   { id: "important", name: "important" },
//   { id: "urgent", name: "urgent" },
// ];

const getView = () => {
  let DictCtxView = {
    model: "hotel.maintenance",
    form_action: [], // options: ['edit', 'delete']
    table_action: [], // options: ['open', 'delete', 'edit', 'add']
    form_rec_name: getName,
    webfields: {
      start_date: {
        type: "date",
        required: true,
        withChange: withChangeDate,
        readOnly: recReadOnly,
      },
      end_date: {
        type: "date",
        required: true,
        withChange: withChangeDate,
        readOnly: recReadOnly,
      },
      issue: {
        type: "text",
        required: true,
        readOnly: recReadOnly,
      },
      room: {
        type: "many2one",
        model: "hotel.room",
        required: true,
        recSearch: searchRooms,
        readOnly: recReadOnly,
      },
      criticality: {
        required: true,
        type: "selection",
        options: CRITICALITY,
        translate: true,
        readOnly: recReadOnly,
      },
      // state: {
      //   // required: true,
      //   type: "selection",
      //   options: STATES,
      //   translate: true,
      //   readOnly: true,
      // },
      confirm: {
        type: "button",
        method: confirmMethod,
        visible: confirmVisible,
        color: "green",
        close: true,
        onSuccessMsg: "Confirmación exitoso!",
      },
      total_days: {
        type: "integer",
        readOnly: true,
        // function: getDays,
      },
    },
    webtree: [
      { name: "room", width: "10%" },
      { name: "start_date", width: "15%" },
      { name: "end_date", width: "15%" },
      { name: "issue", width: "15%" },
      { name: "criticality", width: "15%" },
    ],
    webform: [
      { name: "room" },
      {
        id: "dates",
        grid: [{ name: "start_date" }, { name: "end_date" }],
        size: [1, 2],
      },
      { name: "criticality" },
      { name: "total_days" },
      { name: "issue" },
      { name: "confirm" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
