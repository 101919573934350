import React from "react";

import funcs from "tools/functions";
import { classNames } from "tools/ui";

function CardTagData({ value, color, kind }) {
  console.log("Esta valor....", value);
  let val = value;
  if (kind == "money") {
    val = "$ " + funcs.fmtMoney(value);
  } else if (kind == "rate") {
    val = value + "%";
  }
  let _color = `bg-${color}-200`;
  let txt_color = `text-${color}-700`;
  return (
    <div className={classNames("rounded-lg shadow-sm text-right", _color)}>
      <span className={classNames("p-4", txt_color)}>{String(val)}</span>
    </div>
  );
}

export default CardTagData;
