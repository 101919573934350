import React from "react";
import { signal } from "@preact/signals-react";
import DateField from "components/MeForm/DateField";
import IntegerField from "components/MeForm/IntegerField";
import { FormattedMessage as FM } from "react-intl";
import { classNames } from "tools/ui";

let BASE_STYLE = "space-y-5 rounded-md shadow-md p-10";

const level = "wizard";
const today = new Date();
const ATTRS = {
  level: level,
  minDate: today,
};

const arrival_date = signal();
const departure_date = signal();
const nights_quantity = signal();
const FilterBooking = ({
  onChange,
  nights,
  mode = "aside",
  dates,
  selectClassification,
}) => {
  const disabled = selectClassification?.value.length == 0 ? false : true;
  if (dates.dateStart && dates.dateEnd) {
    arrival_date.value = dates.dateStart;
    departure_date.value = dates.dateEnd;
  }
  if (nights) {
    nights_quantity.value = nights;
  }

  if (mode == "fullWidth") {
    BASE_STYLE =
      "flex flex-row md:flex-row item-center align-center space-x-2 md:space-x-4 max-w-[900px] mx-auto -bottom-4 md:-bottom-16 relative pt-3 pb-5 px-4 md:px-10 md:rounded-md ";
  }

  const fields = {
    arrivalDateField: {
      withChange: onChange,
      name: "arrival_date",
      label: "hotel.booking.add_room.arrival_date",
      readOnly: disabled,
    },
    departureDateField: {
      withChange: onChange,
      name: "departure_date",
      label: "hotel.booking.add_room.departure_date",
      readOnly: disabled,
    },
    nightsQuantity: {
      withChange: onChange,
      name: "nights_quantity",
      label: "hotel.booking.add_rooms.nights_quantity",
      readOnly: true,
    },
  };

  return (
    <div
      className={classNames(
        BASE_STYLE,
        "bg-gray-100  align-middle items-center shadow-sm z-20 relative overflow-hidden",
      )}
    >
      {mode !== "fullWidth" ? (
        <h1 className="text-gray-500 text-sm flex">
          <FM id="web.booking.title_filter" />
        </h1>
      ) : (
        ""
      )}
      <DateField
        field={fields.arrivalDateField}
        attrs={ATTRS}
        data={arrival_date}
      />
      <DateField
        field={fields.departureDateField}
        attrs={ATTRS}
        data={departure_date}
      />
      <div className="md:w-[50%] md:flex hidden">
        <IntegerField
          field={fields.nightsQuantity}
          attrs={ATTRS}
          data={nights_quantity}
          style
        />
      </div>
      {disabled && (
        <div className="absolute flex justify-center items-center text-center px-4 text-blue-presik font-semibold text-2xl bg-white/10 h-full w-full left-[-16px] backdrop-blur-sm top-0 Z-10">
          <p>Elimina items en el carrito para poder filtrar.</p>
        </div>
      )}
    </div>
  );
};

export default FilterBooking;
