import React, { useState, useEffect } from "react";

import Screen from "components/Meta/Screen";
import Board from "components/Meta/Board";
import PanelRight from "components/Panel/PanelRight";
import HeaderPanel from "components/Panel/HeaderPanel";
import SectionSidePanel from "components/Panel/SectionSidePanel";
import opportunityModel from "./ModelOpportunity";
import { classNames } from "tools/ui";
import { leads, activities, winRate } from "./fakeData";
import { signal } from "@preact/signals-react";
import proxy from "api/proxy";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-annotation";

const fmt = "YYYY-MM-DD";
const statusSection = signal(true);
const statusActivities = signal(true);
function Opportunity(props) {
  const { config } = props;
  const ctxView = opportunityModel.ctxView(config);
  const [displayMode, setDisplayMode] = useState(true);
  const [winRateInfo, setWinRateInfo] = useState({});
  const [leadsInfo, setLeadsInfo] = useState([]);
  const [activitiesInfo, setActivitiesInfo] = useState([]);
  const [data, setData] = useState([]);

  function handleDisplay(mode) {
    setDisplayMode(mode);
  }

  function getWinRate() {
    setWinRateInfo({
      title: "crm.opportunity.panel.win_rate",
      elements: winRate,
    });
  }

  function getData() {
    setLeadsInfo(leads);
    setActivitiesInfo(activities);
  }

  useEffect(() => {
    getData();
    getWinRate();
    // getLastReservations();
  }, []);

  let _displayStyle = "";
  if (displayMode) {
    _displayStyle = "md:w-[65vw] xl:w-[70vw] custom-transition duration-500";
  }

  const returnFilter = async (props) => {
    console.log(props, "retorno de data");

    if (props) {
      const { data: res } = await proxy.search("crm.opportunity", props, [
        "id",
        "state",
      ]);

      const counts = Object.keys(ctxView.tags.state).reduce((acc, state) => {
        acc[state] = 0;
        return acc;
      }, {});

      res.forEach((item) => {
        counts[item.state] = (counts[item.state] || 0) + 1;
      });

      console.log(counts);
    }
  };

  const _style = displayMode ? "flex-row justify-between " : "flex-col";

  return (
    <Screen>
      <div
        className={classNames("flex w-full justify-stretch space-x-10", _style)}
      >
        <div
          className={
            statusSection.value == true
              ? "md:w-[65vw] xl:w-[70vw] "
              : "md:w-12/12 relative pr-20"
          }
        >
          <Board ctxView={ctxView} returnFilter={returnFilter} />
        </div>
        <div className="w-[22vw]">
          <PanelRight
            title="SMART CRM"
            // status={displayMode}
            handleDisplay={handleDisplay}
            position={"right"}
            widgets={["status", "numPeople"]}
            bgColor={statusSection.value ? "bg-gray-100" : "bg-blue-presik"}
            status={statusSection.value}
            handleChangeStatus={false}
            displayOpen={true}
            style="bg-gray-100 px-2 custom-transition duration-500 sticky w-full"
          >
            {/* <HeaderPanel
              title="crm.opportunity.panel.status_info"
              dataInfo={winRateInfo}
              name="status_info"
            />
            <SectionSidePanel key="leads" elements={leadsInfo} />
            <SectionSidePanel key="activities" elements={activitiesInfo} /> */}
          </PanelRight>
        </div>
      </div>
    </Screen>
  );
}

export default Opportunity;
