import React, { Fragment, useEffect, useRef, useState } from "react";
import { useWizardStore } from "store/wizardStore";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { getData } from "../Components/dataService";
import { debounce } from "./utils/debounce";

export const InputSearch = ({ type, handleViewInput }) => {
  const { updateWizard } = useWizardStore.getState();
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const [infoGuest, setInfoGuest] = useState(null);

  const searchAffiliate = async () => {
    if (inputValue === "") return;
    if (type !== "read") {
      await handleNonReadSearch();
    } else {
      await handleReadSearch();
    }
  };

  const handleNonReadSearch = async () => {
    const result = await getData(inputValue);
    if (result.data) {
      updateWizard(result.data);
    } else {
      console.log(result.error);
    }
  };
  // const handleReadSearch = async () => {
  //   let dataString = inputValue.replaceAll("?", " ");
  //   const pubDSKRegex = /PubDSK/i; // Expresión regular para hacer la búsqueda insensible a mayúsculas/minúsculas
  //   if (!pubDSKRegex.test(dataString)) {
  //     console.log("paso por aca");
  //   }
  //   dataString = dataString.replace(pubDSKRegex, "0");

  //   const sIMatch = dataString.match("[a-zA-Z]");
  //   if (!sIMatch) {
  //     console.log("No se encontró una letra en la cadena");
  //     return;
  //   }
  //   const sI = sIMatch.index;
  //   dataString = dataString.substring(sI - 10);

  //   const btIndexMatch = dataString.match("[+]|-");
  //   if (!btIndexMatch) {
  //     console.log("No se encontró un '+' o '-' en la cadena");
  //     return;
  //   }
  //   const btIndex = btIndexMatch.index;

  //   let usableData = dataString.substring(0, btIndex + 1);
  //   usableData = usableData.replace(/  +/g, " ");
  //   const dataArray = usableData.split(" ");

  //   const firstAlphaMatch = dataArray[0].match("[a-zA-Z]");
  //   if (!firstAlphaMatch) {
  //     console.log(
  //       "No se encontró una letra en el primer elemento de dataArray",
  //     );
  //     return;
  //   }
  //   const firstAlpha = firstAlphaMatch.index;

  //   const idNumber = Number(dataArray[0].substring(0, firstAlpha)).toString();
  //   setInputValue(idNumber);

  //   const result = await getData(idNumber);
  //   if (result.data) {
  //     updateWizard(result.data);
  //     handleViewInput(false);
  //   } else {
  //     console.log(result.error);
  //   }
  // };

  const handleReadSearch = async () => {
    let dataString = inputValue.replaceAll("?", " ");
    const pubDSKRegex = /PubDSK/i; // Expresión regular para hacer la búsqueda insensible a mayúsculas/minúsculas
    if (!pubDSKRegex.test(dataString)) {
      console.log("paso por aca");
    }
    dataString = dataString.replace(pubDSKRegex, "0");

    // Intentar encontrar la primera letra desde el final si no se encuentra en la búsqueda regular
    const sIMatch = dataString.match(/[a-zA-Z]/);
    if (!sIMatch) {
      console.log("No se encontró una letra en la cadena");
      return;
    }
    const sI = sIMatch.index;
    dataString = dataString.substring(sI - 10);
    console.log(dataString, " esto es el string");

    const match = dataString.match(/^\d+/);
    let number = "";
    if (match) {
      number = match[0].replace(/^0+/, "");
    } else {
      number = false;
    }
    if (number && /^[0-9]+$/.test(number)) {
      const { data, error } = await getData(number);
      if (data) {
        updateWizard(data);
        handleViewInput(false);
      } else {
        updateWizard({ result: 404 });
        console.log("paso mal todo");
      }
    }

    setInputValue(number);

    return false;
  };

  const debouncedSearch = debounce(searchAffiliate, 2000);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (type === "read") {
      debouncedSearch();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    searchAffiliate();
  };

  return (
    <Fragment>
      <form
        onSubmit={handleSubmit}
        className={`mx-auto w-full bg-gray-100 border border-gray-300 shadow-md p-1 max-w-md mb-5 rounded-md flex justify-between ${type === "read" ? "opacity-0 absolute" : "visible"}`}
      >
        <input
          type="text"
          id="searchActi"
          ref={inputRef}
          value={inputValue}
          onChange={handleChange}
          placeholder="Digita el # de cedula"
          className={"bg-transparent w-full outline-none px-2 min-w-96 mx-auto"}
        />
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded-md flex items-center"
        >
          <MagnifyingGlassIcon className="h-5 w-5" />
        </button>
      </form>
    </Fragment>
  );
};
