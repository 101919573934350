import proxy from "api/proxy";

const GetFields = () => {
  const TYPES_LEADS = [
    { id: "action", name: "Action" },
    { id: "call", name: "Call" },
    { id: "on_site_appointment", name: "On-Site Appointment" },
    { id: "virtual_appointment", name: "Virtual Appointment" },
    { id: "chat", name: "Chat" },
    { id: "email", name: "Email" },
  ];

  return TYPES_LEADS;
};
// const withChangeProduct = (record, store) => {
//   if (record.product) {
//     const product = record.product;
//     let uom = product["sale_uom."];
//     let unit_price = product.sale_price_taxed;
//     unit_price = parseFloat(unit_price?.toString() || 0);
//     store.unit_price = unit_price;
//     store.unit = uom.id;
//     record.unit_price = unit_price;
//     record.unit_price_w_tax = record.product.sale_price_taxed;
//     record.unit = uom;
//   }
// };
const GetCustomer = (record) => {
  console.log("records", record);
};

const getView = () => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    model: "crm.activity",
    form_action: ["add", "edit"],
    table_action: ["add", "edit"],
    validate_state: true,
    webfields: {
      customer: {
        type: "many2one",
        model: "crm.prospect",
        // required: true,
        default: GetCustomer(),
        readOnly: true,
      },
      // unit: {
      //   type: "many2one",
      //   model: "product.uom",
      //   readOnly: true,
      // },
      subject: { type: "char" },
      state: { type: "char", readOnly: true },
      type_info: { type: "char" },
      description: { type: "text" },
      planned_time: { type: "datetime" },
      planned_date: { type: "date" },
      effective_date: { type: "date" },
      type_: { type: "selection", options: GetFields(), translate: true },
      agent: {
        type: "many2one",
        model: "crm.activity.agent",
        searchable: true,
      },
    },
    webtree: [
      { name: "state", width: "30%" },
      { name: "type_", width: "30%" },
      { name: "planned_date", width: "30%" },
      { name: "effective_date", width: "30%" },
    ],
    webform: [
      { name: "customer" },
      { name: "state" },
      { name: "subject" },
      { name: "type_", width: "30%" },
      { name: "type_info", width: "30%" },
      { name: "planned_date", width: "30%" },
      // { name: "planned_time", width: "30%" },
      { name: "effective_date", width: "30%" },
      { name: "agent", width: "30%" },
      { name: "description", width: "30%", colspan: 2 },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
