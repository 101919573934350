import React from "react";
import date from "date-and-time";

import PopupContentSection from "./PopupContentSection";
import { fmtCurrency } from "tools/ui";

const fmtDate = "YYYY-MM-DD";

function PopupContent({ operation }) {
  let {
    accomodation,
    from_date,
    to_date,
    issue,
    kind,
    unit_price_w_tax,
    guests,
    agent,
    booking,
    create_user,
    payments,
  } = operation;

  if (from_date instanceof Date) {
    from_date = date.format(from_date, fmtDate);
  }
  if (to_date instanceof Date) {
    to_date = date.format(to_date, fmtDate);
  }

  if (kind === "accommodation") {
    return (
      <div className="grid">
        <p className="text-md font-bold text-zinc-500">{booking}</p>
        <p className="text-base text-zinc-500">{accomodation}</p>
        <PopupContentSection
          label1="Check-In"
          value1={from_date}
          label2="Check-Out"
          value2={to_date}
        />
        <PopupContentSection
          label1="Guests"
          value1={guests.length}
          label2="Price / Night"
          value2={fmtCurrency(unit_price_w_tax)}
        />
        <PopupContentSection
          label1="Agent"
          value1={agent}
          label2="Payments"
          value2={fmtCurrency(payments)}
        />
      </div>
    );
  } else {
    return (
      <div className="text-lg text-zinc-500">
        <p className="text-lg">Desde: {from_date}</p>
        <p className="text-lg">Hasta: {to_date}</p>
        <p className="text-lg">Notas: {issue}</p>
        <p className="text-lg">Creado por: {create_user}</p>
      </div>
    );
  }
}

export default PopupContent;
