import React from "react";

import version from "version";

function Footer() {
  return (
    <footer class="mx-auto py-5 bottom-0">
      <div class="mx-auto">
        <p class="text-center text-gray-400">
          Copyright© 2024 All rights reserved.
        </p>
        <p class="text-center text-gray-400">Version {version}</p>
        <a
          class="block text-center text-gray-600 hover:text-yellow-400"
          href="http://www.presik.com"
        >
          PRESIK SAS
        </a>
      </div>
    </footer>
  );
}

export default Footer;
