import React, { Fragment, useState } from "react";
import proxy from "api/proxy";
import { useWizardStore } from "store/wizardStore";
import { InputSearch } from "./Components/InputSearch";
import StdButton from "components/Buttons/StdButton";
import ListData from "./Components/ListData";
import ItemPerson from "./Components/ItemPerson";
import PureModal from "components/Modals/PureModal";
import dates from "tools/dates";
import LoadingIcon from "components/Tools/LoadingIcon.js";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import {
  IdentificationIcon,
  InboxArrowDownIcon,
} from "@heroicons/react/24/solid";
import { Alert } from "components/Alerts/Alert";
function groupByAssociateProfile(guests) {
  const grouped = {};

  guests.forEach((guest) => {
    const profileID = guest?.associate_profile;

    if (!grouped[profileID]) {
      grouped[profileID] = [];
    }

    grouped[profileID].push(guest);
  });
  let res = Object.keys(grouped).length ? grouped : false;
  console.log("res grupo", res);

  return res;
}
const ScreenAffiliates = () => {
  const { updateWizard, reset, store } = useWizardStore();
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [viewInput, setViewInput] = useState(null);
  let today = new Date();
  let payment_until = new Date(
    store.payment_until || store?.["associate."]?.payment_until,
  );
  let enabled = payment_until < today ? false : true;

  const groupedGuests = store?.["guests."]
    ? groupByAssociateProfile(store?.["guests."])
    : false;

  const ItemInfo = ({ label = null, value = null, image = true }) => (
    <div className="flex space-x-3 text-xl pt-2">
      {label === "Nombre" && image && (
        <img
          src={`https://ui-avatars.com/api/?name=${value}&background=0D8ABC&color=fff`}
          className="w-10 h-10 rounded-full"
          alt="avatar"
        />
      )}
      <div className="flex flex-col">
        <span className="font-semibold uppercase">{label}:</span>
        <span className="text-base">{value}</span>
      </div>
    </div>
  );

  const ContentInfoGuest = ({ guest }) => {
    if (!guest) return false;
    const party = guest?.["party."];
    return (
      <div className="rounded-lg overflow-hidden">
        <div className="px-6 py-3 bg-blue-presik text-white text-xl flex justify-between items-center">
          <h2>Información de persona</h2>
          <XMarkIcon
            className="w-10 bg-red-400 text-red-950 rounded-full p-2 cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="min-h-96 p-6 grid grid-cols-2">
          <div>
            <ItemInfo label="Nombre" value={party?.name} image={false} />
            <ItemInfo label="Cedula" value={party?.id_number} />
            <ItemInfo label="Dirección" value={party?.id_number} />
            <ItemInfo label="Edad" value={12} />
          </div>
          <div>
            <ItemInfo label="Telefono" value={party?.id_number} />
            <ItemInfo label="Email" value={"vr24355@gmail.com"} />
            <ItemInfo label="Cumpleaños" value="28/12/1992" />
            <ItemInfo label="Sexo" value="Femenino" />
          </div>
        </div>
      </div>
    );
  };

  const ContentInputScan = () => (
    <div className="rounded-lg overflow-hidden">
      <div className="px-6 py-3 bg-blue-presik text-white text-xl flex justify-between items-center">
        <h2>Escáner</h2>
        <XMarkIcon
          className="w-10 bg-red-400 text-red-950 rounded-full p-2 cursor-pointer"
          onClick={() => setOpen(false)}
        />
      </div>
      <div className="min-h-96 p-6 flex flex-col justify-center items-center space-y-5 relative">
        <LoadingIcon size="42px" />
        <InputSearch type="read" handleViewInput={() => setOpen(false)} />
        {/* <ArrowsPointingInIcon className="w-20" /> */}
        <span className="text-3xl font-medium max-w-[400px] text-center">
          Por favor, pasa el documento en el escáner.
        </span>
      </div>
    </div>
  );

  const handlerOpenModal = (props) => {
    setOpen(true);
    setModalContent(<ContentInfoGuest guest={props} />);
  };

  const addAccess = async () => {
    const dateNow = dates.fmtDatetime2Tryton(new Date());
    const model = "associate.access";
    const fields_names = ["id"];
    const toStore = {
      associate: store.id,
      enter_timestamp: dateNow,
    };
    const data = await proxy.create(model, toStore, fields_names);
    if (data) {
      setShowAlert(true);
      reset();
      setModalContent(null);
    }
  };

  const MethodSearch = () => (
    <div className="grid grid-cols-2 max-w-[640px] gap-x-10 mx-auto text-xl font-medium mb-10">
      <div
        className="bg-blue-presik text-white p-6 flex items-center space-x-3 rounded-md shadow-md hover:scale-95 transition-all duration-150 cursor-pointer"
        onClick={() => {
          setModalContent(<ContentInputScan />);
          setOpen(true);
        }}
      >
        <InboxArrowDownIcon className="w-14" />
        <span>Leer Documento</span>
      </div>
      <div
        className="bg-yellow-500 text-blue-presik p-6 flex items-center space-x-3 rounded-md shadow-md hover:scale-95 transition-all duration-150 cursor-pointer"
        onClick={() => setViewInput("write")}
      >
        <IdentificationIcon className="w-14" />
        <span>Ingresar Documento</span>
      </div>
    </div>
  );

  return (
    <div className="px-4 py-10 flex flex-col w-full h-full relative">
      {viewInput === "write" && !store.id && (
        <ArrowLeftIcon
          className="w-14 p-2 bg-blue-presik text-white rounded-full absolute right-6 top-6 cursor-pointer"
          onClick={() => setViewInput(null)}
        />
      )}
      {(viewInput == "read" && !store.id) || (!viewInput && <MethodSearch />)}
      {viewInput === "write" && (
        <div className="w-full flex flex-col justify-center mb-6">
          <InputSearch type="write" handleViewInput={() => null} />
          {!store.id && (
            <span className="text-gray-500 text-center">
              Digita un número de cédula del afiliado, invitado, o círculo
              familiar
            </span>
          )}
        </div>
      )}
      {store.id && (
        <div className="min-h-[60vh] flex flex-col justify-start bg-gray-100 p-8 shadow-md rounded-md space-y-6">
          <div className="flex flex-col justify-center items-center space-y-4">
            <img
              className="inline-block h-20 w-20 mx-auto rounded-full"
              src={`https://ui-avatars.com/api/?name=${store["party."]?.name}&background=0D8ABC&color=fff`}
              alt="user"
            />
            <span
              className={`${enabled ? "bg-green-500 text-green-900" : "bg-red-400 text-red-900"} rounded-md  py-2 px-8 uppercase flex space-x-3 items-center`}
            >
              <span>{enabled ? "Habilitado" : "Inactivo"}</span>
              {enabled ? (
                <CheckCircleIcon className="w-6 text-white" />
              ) : (
                <XMarkIcon className="w-5 text-red-700 bg-white rounded-full  p-1" />
              )}
            </span>
          </div>
          <div className="grid grid-cols-3 justify-items-center gap-x-10">
            <ListData title="Información">
              <div className="space-y-3 divide-y divide-gray-100 p-4">
                <ItemInfo label="Nombre" value={store["party."]?.name} />
                <ItemInfo label="Cedula" value={store["party."]?.id_number} />
                <ItemInfo
                  label="Perfil"
                  value={store["associate_profile."]?.name}
                />
                <ItemInfo
                  label="Descripcion perfil"
                  value={store["associate_profile."]?.description}
                />

                <ItemInfo
                  label="Fechas vigentes"
                  value={
                    store.payment_until || store?.["associate."]?.payment_until
                  }
                />
                {store?.["member_ship."] != null && (
                  <Fragment>
                    <ItemInfo
                      label="Membresia"
                      value={store?.["member_ship."].name}
                    />
                    <ItemInfo
                      label="Días"
                      value={store?.["member_ship."].total_days}
                    />
                  </Fragment>
                )}
              </div>
              {store?.["associate."] != null && (
                <Fragment>
                  <h2 className="p-4 py-1 bg-blue-presik text-white text-center text-xl font-medium">
                    Principal afiliado
                  </h2>
                  <div className="space-y-3 divide-y divide-gray-100 p-4">
                    <ItemInfo
                      label="Nombre"
                      value={store["associate."]["party."]?.name}
                    />
                    <ItemInfo
                      label="Cedula"
                      value={store["associate."]["party."]?.id_number}
                    />
                  </div>
                </Fragment>
              )}
            </ListData>
            <ListData title="Círculo familiar">
              <div className="divide-y divide-gray-100">
                {groupedGuests[12] && groupedGuests[12].length > 0 ? (
                  groupedGuests[12].map((item) => (
                    <ItemPerson
                      key={item.id}
                      item={item}
                      handlerOpenModal={handlerOpenModal}
                    />
                  ))
                ) : (
                  <p className="px-4 py-2">No hay datos disponibles</p>
                )}
              </div>
            </ListData>
            <ListData title="Presentado">
              <div className="divide-y divide-gray-100">
                {groupedGuests[2] && groupedGuests[2].length > 0 ? (
                  groupedGuests[2].map((item) => (
                    <ItemPerson
                      key={item.id}
                      item={item}
                      handlerOpenModal={handlerOpenModal}
                    />
                  ))
                ) : (
                  <p className="px-4 py-2">No hay datos disponibles</p>
                )}
              </div>
            </ListData>
          </div>
          <div className="flex justify-center bo space-x-4">
            {enabled && (
              <StdButton
                color="bluePresik"
                style="w-[260px] hover:scale-95 transition duration-100"
                onClick={() => addAccess()}
              >
                REGISTRAR ENTRADA
              </StdButton>
            )}

            <StdButton
              color="rose"
              style="w-[260px] hover:scale-95 transition duration-100"
              onClick={() => {
                setViewInput(null);
                reset();
              }}
            >
              CANCELAR
            </StdButton>
          </div>
        </div>
      )}
      <PureModal open={open} onClose={() => setOpen(false)} backdrop={true}>
        {modalContent}
      </PureModal>
      <Alert
        text="Registrado correctamente"
        show={showAlert}
        onClose={() => setShowAlert(false)}
        color="bg-blue-presik text-white"
        position="fixed bottom-10 right-10"
      />
      <Alert
        text="Cedula no encontrada en el sistema"
        show={!store?.id && store?.result ? true : false}
        onClose={() => reset()}
        color="bg-red-300 text-red-900"
        position="fixed bottom-10 right-10 z-[540]"
      />
    </div>
  );
};

export default ScreenAffiliates;
