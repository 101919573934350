import React from "react";
import FormField from "./FormField";
import { classNames, colors } from "tools/ui";
import upStore from "store/upStore";

const style =
  "h-10 rounded-md shadow-sm w-full text-right py-2 px-3 text-gray-700 border border-slate-300 focus:outline-none focus:border focus:border-cyan-500";

const PATTERN = /^-?\d*\.?\d{0,6}$/;
const formatter = Intl.NumberFormat("en-US");

function NumericField(props) {
  const { field, attrs, data } = props;
  const { name, readOnly, widget, decimalPlaces } = field;
  const { level } = attrs;

  function onChange(event) {
    let _value = event.target.value.replaceAll(",", "");

    // Validación del patrón
    if (!PATTERN.test(_value)) {
      return;
    }

    // Formatear el valor mientras se escribe
    if (_value) {
      _value = parseFloat(_value).toFixed(decimalPlaces || 0);
      if (widget && widget === "percent") {
        _value = _value / 100;
      }
    } else {
      _value = "0";
    }

    // Actualizar el valor formateado
    data.value = formatter.format(_value);

    // Guardar en el store en tiempo real
    upStore(level, {
      fieldName: name,
      value: _value,
      valueActive: data.value,
      field: field,
    });
  }

  const onBlur = () => {
    if (data.value && data.value !== "") {
      let _value = data.value.replaceAll(",", "");
      _value = parseFloat(_value).toFixed(decimalPlaces || 0);
      if (widget && widget === "percent") {
        _value = _value / 100;
      }
      _value = formatter.format(_value);
      data.value = _value;
    }
  };

  let _color = readOnly ? colors.readOnly : props.color ?? "bg-white";

  let moreTools;
  if (field.widget && field.widget === "percent") {
    moreTools = <p className="pl-2 my-auto text-stone-700 text-sm">%</p>;
  }

  return (
    <FormField {...field}>
      <div className="flex">
        <input
          id={name}
          type="text"
          name={name}
          className={classNames(style, _color)}
          value={data?.value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={readOnly}
          placeholder={field.placeholder ?? ""}
        />
        {field.icon || null}
        {moreTools}
      </div>
    </FormField>
  );
}

export default NumericField;

// import React from "react";
// import FormField from "./FormField";
// import { classNames, colors } from "tools/ui";
// import upStore from "store/upStore";

// const style =
//   "h-10 rounded-md shadow-sm w-full text-right py-2 px-3 text-gray-700 border border-slate-300 focus:outline-none focus:border focus:border-cyan-500";

// const PATTERN = /^-?\d*\.?\d{0,6}$/;
// const formatter = Intl.NumberFormat("en-US");

// function NumericField(props) {
//   console.log("paso por aca");

//   const { field, attrs, data } = props;
//   const { name, readOnly, widget, decimalPlaces } = field;
//   const { level } = attrs;

//   function onChange(event) {
//     let _value = event.target.value;
//     _value = _value.replaceAll(",", "");
//     const validNumber = PATTERN.test(_value);

//     if (!validNumber) {
//       return;
//     }
//     data.value = _value;
//   }

//   const onBlur = () => {
//     let _value;
//     if (data.value && data.value !== "") {
//       _value = data.value.replaceAll(",", "");
//       _value = parseFloat(_value).toFixed(decimalPlaces || 0);
//       if (widget && widget == "percent") {
//         _value = _value / 100;
//       }
//       _value = _value.toString();
//     } else {
//       _value = 0;
//     }
//     data.value = formatter.format(_value);
//     upStore(level, {
//       fieldName: name,
//       value: _value,
//       valueActive: data.value,
//       field: field,
//     });
//   };

//   let _color = readOnly ? colors.readOnly : props.color ?? "bg-white";

//   let moreTools;
//   if (field.widget && field.widget === "percent") {
//     moreTools = <p className="pl-2 my-auto text-stone-700 text-sm">%</p>;
//   }

//   return (
//     <FormField {...field}>
//       <div className="flex">
//         <input
//           id={name}
//           type="text"
//           name={name}
//           className={classNames(style, _color)}
//           value={data?.value}
//           onChange={onChange}
//           onBlur={onBlur}
//           disabled={readOnly}
//           placeholder={field.placeholder ?? ""}
//         />
//         {field.icon || null}
//         {moreTools}
//       </div>
//     </FormField>
//   );
// }

// export default NumericField;
