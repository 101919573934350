import React from "react";

const ListData = ({ title, children }) => {
  return (
    <div className="bg-white border border-gray-200 overflow-hidden rounded-lg shadow-sm w-full ">
      <h2 className="p-4 bg-blue-presik text-white text-center text-xl font-medium">
        {title}
      </h2>
      <div className="">{children}</div>
    </div>
  );
};

export default ListData;
