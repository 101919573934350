import React from "react";

import SegChart from "components/Chart/SegChart";
import "./chart.css";
// className="grid pt-6 gap-4 "

function GridChart({ accReports }) {
  return (
    <div
      id="grid-chart"
      className="items-start grid-cols-1 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-6 gap-4 w-full sm:w-4/5 md:w-full lg:w-full xl:w-2/3"
    >
      {Object.values(accReports).map((report, idx) => (
        <SegChart key={idx} report={report} />
      ))}
    </div>
  );
}

export default GridChart;
