import React from "react";

import InfoCard from "components/Chart/InfoCard";

function InfoChart({ data, name, color, getReport }) {
  let value = data.value;
  if (value && !isNaN(data.value)) {
    value = Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    });
  }

  let legend = null;
  if (data.args && data.args.legend) {
    legend = (
      <p className="text-lg md:text-2xl font-bold text-lime-600 pl-6 pb-4">
        {data.args.legend}
      </p>
    );
  }

  console.log("data...", data);
  return (
    <InfoCard
      header={name}
      header_meta={data.header_meta}
      desc={data.desc}
      desc_meta={data.desc_meta}
      prev_value={data.prev_value}
      rate_prev_value={data.rate_prev_value}
      color={color}
      getReport={getReport}
    >
      <div id="info-chart" className="flex">
        <p className="text-4xl sm:text-4xl md:text-4xl lg:text-4xl mx-auto text-slate-600 font-bold py-4">
          {value}
        </p>
        {legend}
      </div>
    </InfoCard>
  );
}

export default InfoChart;
