import React, { Fragment, useEffect, useState } from "react";
import proxy from "api/proxy";
import func from "../../tools/functions";
import CharField from "components/MeForm/CharField";
import { signal } from "@preact/signals-react";
import imgDefault from "../../assets/img/default-image.jpg";
import TextField from "components/MeForm/TextField";
import StdButton from "components/Buttons/StdButton";
import { useWizardStore } from "store/wizardStore";
import { Alert } from "components/Alerts/Alert";
import { checkRequiredForm } from "tools/form";
import SectionNoData from "components/Tools/SectionNoData";

const ATTRS = {
  level: "wizard",
};

const fields = {
  firstNameField: {
    name: "first_name",
    label: "sale.gift.first_name",
    type: "char",
    required: true,
  },
  lastNameField: {
    name: "last_name",
    label: "sale.gift.last_name",
    type: "char",
    required: true,
  },
  phoneField: {
    name: "phone",
    label: "sale.gift.phone",
    type: "char",
    required: true,
  },
  emailField: {
    name: "email",
    label: "sale.gift.email",
    type: "char",
    required: true,
  },
  fromField: {
    name: "from",
    label: "sale.gift.from",
    type: "char",
    required: true,
  },
  toField: {
    name: "to",
    label: "sale.gift.to",
    type: "char",
    required: true,
  },
  phoneGiftField: {
    name: "phone_gift",
    label: "sale.gift.phone_gift",
    type: "char",
    required: true,
  },
  addressGiftField: {
    name: "address_gift",
    label: "sale.gift.address_gift",
    type: "char",
    required: true,
  },
  messageGiftField: {
    name: "message_gift",
    label: "sale.gift.message_gift",
    type: "char",
    required: true,
  },
  receiveGiftField: {
    name: "receive_gift",
    label: "sale.gift.receive",
    type: "char",
    required: true,
  },
};

const storeRequired = [
  "first_name",
  "last_name",
  "phone",
  "email",
  "from",
  "to",
  "receive_gift",
  "phone_gift",
  "address_gift",
  "message_gift",
];

const first_name = signal("");
const last_name = signal("");
const phone = signal("");
const email = signal("");
const from = signal("");
const to = signal("");
const phone_gift = signal("");
const address_gift = signal("");
const message_gift = signal("");
const receive_gift = signal("");
const alertN = signal(null);

const SaleGift = () => {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const number = params.get("number");
  const [data, setData] = useState(null);
  const { record, store, reset: resetWizard } = useWizardStore();

  const getInvoice = async () => {
    const dom = [["number", "=", number]];
    const fields = [
      "id",
      "total_amount",
      "lines",
      "lines.id",
      "lines.unit_price_w_tax",
      "lines.quantity",
      "lines.product.name",
      "lines.product.list_price",
      "lines.product.sale_price_w_tax",
      "lines.product.images.image_url",
      "lines.product.image_url",
      "web_shop",
    ];

    const { data, error } = await proxy.search("sale.sale", dom, fields, 1);
    if (data) {
      setData(data[0]);
      console.log(data[0]);
    } else {
      console.log(error);
    }
  };
  useEffect(() => {
    getInvoice();
  }, [number]);

  const addPayment = async () => {
    const validation = checkRequiredForm(store, storeRequired);
    if (!validation) {
      alertN.value = "Llena todos los campos requeridos";
      return false;
    }
    const _lines = data["lines."].map((item) => ({
      id: item.id,
      gift_of: from,
      gift_for: to,
      phone_gift: phone_gift.value,
      address_gift: address_gift.value,
      message_gift: message_gift.value,
      receive: receive_gift.value,
    }));

    const sale = {
      sale: data.id,
      customer_name: first_name.value,
      customer_last_name: last_name.value,
      customer_email: email.value,
      customer_contact: phone.value,
      payment_gateway: "openpay",
      lines: _lines,
    };
    const args = {
      model: "web.shop",
      method: "psf_data_gift_card",
      instance: data.web_shop,
      args: [sale],
    };
    const { data: resCharge } = await proxy.methodInstance(args);

    if (resCharge) {
      window.location.href =
        resCharge.payment_intent.payment_intent_info.redirect_url;
    } else {
      alertN.value = "Ocurrio un error al momento de crear el cargo";
    }
  };

  const ItemProduct = () => {
    return (
      <Fragment>
        <h2 className="text-2xl m-5 ">Tu pedido</h2>
        <div className="space-y-4 p-4">
          {data &&
            data["lines."].map((item, key) => {
              return (
                <div
                  className="bg-white p-4 flex space-x-4 items-center rounded-md shadow-sm"
                  key={key}
                >
                  <img
                    src={
                      item["product."]["images."][0]
                        ? item["product."]["images."][0].image_url
                        : imgDefault
                    }
                    className="h-20 w-20 rounded-full  object-cover"
                  />

                  <div className="flex flex-col space-y-1">
                    <span className="font-semibold">
                      {item["product."].name}
                    </span>
                    <span>
                      {item.unit_price_w_tax} x {item.quantity}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="mt-8 font-medium text-2xl text-center bg-gray-200 py-5 px-5">
          Total: {data?.total_amount}
          <StdButton
            style="mx-auto text-xl"
            size={"w-[350px] mt-4"}
            color="yellowPresik"
            onClick={() => addPayment()}
          >
            PROCESAR PAGO
          </StdButton>
        </div>
      </Fragment>
    );
  };

  if (!data) {
    return (
      <div className="p-10">
        <SectionNoData text="No existen ventas con este número" />;
      </div>
    );
  }
  return (
    <Fragment>
      <header className="w-full bg-gray-100 pb-5">
        <div className="container mx-auto ">
          <img
            src="https://www.floristeriabukaflor.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdl4sdiukx%2Fimage%2Fupload%2Fv1706732572%2Fh1miumkd2j2isqi26epj.png&w=1920&q=75"
            className="max-w-[200px]"
          />
        </div>
      </header>
      <div className="flex min-h-full space-x-10 justify-between container mx-auto mt-10 bg-gray-100 rounded-md p-6 max-w-7xl">
        <div className="w-3/5 relative">
          <h1 className="text-3xl">
            Finaliza tu pedido aquí <b>{number}</b>
          </h1>
          <h3 className="text-2xl font-medium mt-4">Agrega tus datos</h3>
          <div className="grid grid-cols-2 gap-x-5 relative">
            <CharField
              field={fields.firstNameField}
              attrs={ATTRS}
              data={first_name}
            />
            <CharField
              field={fields.lastNameField}
              attrs={ATTRS}
              data={last_name}
            />
            <CharField field={fields.phoneField} attrs={ATTRS} data={phone} />
            <CharField field={fields.emailField} attrs={ATTRS} data={email} />
            <div className="bg-white mt-5 p-4 col-span-2 rounded-md shadow-md grid grid-cols-2 gap-x-4">
              <div className="col-span-2">
                <h3 className="text-2xl font-medium">
                  Datos de la tarjeta de regalo
                </h3>
              </div>
              <CharField field={fields.fromField} attrs={ATTRS} data={from} />
              <CharField field={fields.toField} attrs={ATTRS} data={to} />
              <CharField
                field={fields.receiveGiftField}
                attrs={ATTRS}
                data={receive_gift}
              />
              <CharField
                field={fields.phoneGiftField}
                attrs={ATTRS}
                data={phone_gift}
              />
              <CharField
                field={fields.addressGiftField}
                attrs={ATTRS}
                data={address_gift}
              />
              {/* <CharField field={fields.fromField} attrs={ATTRS} data={from} /> */}
              <div className="col-span-2">
                <TextField
                  field={fields.messageGiftField}
                  attrs={ATTRS}
                  data={message_gift}
                />
              </div>
              {/* <CharField field={fields.date} attrs={ATTRS} data={last_name} /> */}
            </div>
          </div>
          {alertN.value && (
            <Alert
              text={alertN.value}
              show={alertN ? true : false}
              onClose={() => (alertN.value = null)}
            />
          )}
        </div>
        <div className="w-2/5 bg-gray-50 shadow-sm ">
          <ItemProduct />
        </div>
      </div>
      <div className="flex justify-center w-full"></div>
    </Fragment>
  );
};

export default SaleGift;
