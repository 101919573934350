import React, { useState, useEffect } from "react";

import SegLabel from "./SegLabel";
import SegLoader from "./SegLoader";
import BarChart from "components/Chart/BarChart";
import LineChart from "components/Chart/LineChart";
import InfoChart from "components/Chart/InfoChart";
import DoughnutChart from "components/Chart/DoughnutChart";
import TableChart from "components/Chart/TableChart";
import fetchReport from "./fetchReport";
import dataFake from "./dataFake";
// import BarLineChart from "components/Chart/BarLineChart";

function SegChart({ report }) {
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(true);
  let [moment, setMoment] = useState("current");
  // console.log("report...", report);

  let component;

  async function getReport() {
    const data = await fetchReport({ report, moment });
    // console.log("data.......", data)

    if (data) {
      if (moment === "current") {
        setMoment("previous");
      } else {
        setMoment("current");
      }
      setData(data);
      setLoading(false);
    }
  }

  useEffect(() => {
    getReport();
  }, []);

  let style = "";
  // let style = "active:scale-95";
  if (report.colspan) {
    style = `col-span-${report.colspan}`;
  } else if (report.type === "bar" || report.type === "line") {
    style = "col-span-2";
  }
  if (data.length === 0) {
    component = <SegLabel {...report} />;
  } else {
    if (report.type === "bar") {
      component = (
        <BarChart {...report} data={data} getReport={getReport} style={style} />
      );
    } else if (report.type === "line") {
      component = <LineChart {...report} data={data} getReport={getReport} />;
    } else if (report.type === "card_info") {
      // let default_option = null;
      // if (data.default_option) {
      //   default_option = data.selector[data.default_option];
      // }
      component = <InfoChart {...report} data={data} getReport={getReport} />;
      if (data.value && data.value.length > 6) {
        // computerCol = 7;
      }
    } else if (report.type === "doughnut") {
      component = (
        <DoughnutChart {...report} data={data} getReport={getReport} />
      );
    } else if (report.type === "table") {
      component = (
        <TableChart
          {...report}
          data={dataFake}
          getReport={getReport}
          style={style}
        />
      );
    }
  }

  return loading ? <SegLoader /> : component;
}

export default SegChart;
