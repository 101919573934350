import date from "date-and-time";
import store from "store";

import proxy from "api/proxy";

const fmt = "YYYY-MM-DD";

async function getOwnerUser() {
  const session = store.get("ctxSession");
  const model = "hotel.room-res.user";
  const dom = [["user", "=", session.user]];
  const fields_names = ["id"];
  const { data } = await proxy.search(model, dom, fields_names, 10);
  if (data.length > 0) {
    return true;
  }
  return false;
}

function convertFolio(folio) {
  let party = "";
  let contact = folio["booking."].contact;
  let main_guest = "";
  if (folio["booking."]["party."]) {
    party = folio["booking."]["party."].name;
  }
  if (folio["main_guest."]) {
    main_guest = folio["main_guest."].name;
  }
  let channel_name = "";
  let channel_code = "";
  let agent_name = "";
  if (folio["booking."]["channel."]) {
    channel_name = folio["booking."]["channel."].rec_name;
    channel_code = folio["booking."]["channel."].code;
  }
  if (folio["booking."]["agent."]) {
    agent_name = folio["booking."]["agent."].rec_name;
  }

  let payments = 0;
  if (folio["booking."]["payments."]) {
    for (const pay of folio["booking."]["payments."]) {
      payments += pay.amount;
    }
  }

  return {
    id: folio.id,
    from_date: folio.arrival_date,
    to_date: folio.departure_date,
    status: folio.registration_state,
    num_adults: folio.num_adults,
    num_children: folio.num_children,
    guests: folio.guests,
    charges: folio.charges,
    agent: agent_name,
    unit_price_w_tax: folio.unit_price_w_tax,
    kind: "accommodation",
    arrival_date: date.parse(folio.arrival_date, fmt),
    departure_date: date.parse(folio.departure_date, fmt),
    room_id: folio["room."].id,
    room: folio["room."].name,
    accomodation: folio["product."].rec_name,
    media: folio["booking."].media,
    booking: folio["booking."].number,
    payments: payments,
    guest_name: main_guest,
    party: party,
    contact: contact,
    channel: channel_name,
    channel_code: channel_code,
    nationality: folio["nationality."] ? folio["nationality."].name : "",
  };
}

function convertOpMant(mnt) {
  return {
    id: mnt.id,
    room_id: mnt["room."].id,
    room: mnt["room."].name,
    from_date: mnt.start_date,
    to_date: mnt.end_date,
    status: "maintenance",
    issue: mnt.issue,
    kind: "maintenance",
    create_user: mnt["create_uid."]["name"],
  };
}

const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + "-" + s4() + "-" + s4();
};

const durationOfDays = (to_date, from_date) => {
  const from_ = new Date(from_date).getTime();
  const to_ = new Date(to_date).getTime();
  return (to_ - from_) / (60 * 60 * 24 * 1000);
};

/**
 * Generates dates from start to number of days
 * @param {*} startDate
 * @param {*} numberOfDays
 */
const generateDates = (startDate, numberOfDays) => {
  let from_date = new Date(startDate);
  let dates = [];

  dates.push(from_date.toDateString());
  for (var aa = 0; aa < numberOfDays - 1; aa++) {
    from_date.setDate(from_date.getDate() + 1);
    dates.push(from_date.toDateString());
  }
  return dates;
};

/**
 * Return All booking dates for the given booking
 **/
export const getAllBookingDates = (singleBooking) => {
  let from_date = new Date(singleBooking.from_date);
  let numberOfDays = numberOfDaysOfBooking(singleBooking);
  return generateDates(from_date, numberOfDays);
};

/**
 * Get number of days of booking
 * @param {*} booking
 */
export const numberOfDaysOfBooking = (booking) => {
  return (
    (new Date(booking.to_date).getTime() -
      new Date(booking.from_date).getTime()) /
      (60 * 60 * 24 * 1000) +
    1
  );
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonthName = function (rawDate) {
  return monthNames[rawDate.getMonth()];
};

export const getShortMonthName = function (rawDate) {
  return getMonthName(rawDate).substr(0, 3);
};

const helpers = {
  getAllBookingDates: getAllBookingDates,
  convertOpMant: convertOpMant,
  guid: guid,
  durationOfDays: durationOfDays,
  convertFolio: convertFolio,
  getMonthName: getMonthName,
  getShortMonthName: getShortMonthName,
  getOwnerUser: getOwnerUser,
};

export default helpers;
