// Folio model
import store from "store";
import date from "date-and-time";

import dates from "tools/dates";
import tools from "tools/common";
import modelGuest from "./ModelGuest";
import modelCharge from "./ModelCharge";
import proxy from "api/proxy";
import { useWizardStore } from "store/wizardStore";
import funcs from "tools/functions";
import helper from "./calendar/helper";

let today = tools.fmtDate2Tryton(new Date());

const searchProduct = () => {
  return [
    ["template.type", "=", "service"],
    ["template.kind", "=", "accommodation"],
  ];
};

async function searchChannel() {
  const rolOwner = await helper.getOwnerUser();
  if (rolOwner) {
    return [["kind", "=", "owner"]];
  }
  return [];
}

const searchRooms = () => {
  const session = store.get("ctxSession");
  return [["authorized_users", "in", [session.user]]];
};

const getName = (record) => {
  return record.booking;
};

function getAgent(record) {
  return record?.booking?.agent;
}

function readOnlyChannel(record) {
  console.log("record ...", record);
  if (record.id > 0) return true;
  return false;
}

const onChangeRoom = (record, storeRec) => {
  const { list_price, sale_price_w_tax, name, products } =
    record.room["main_accommodation."];
  record.unit_price = list_price;
  storeRec.unit_price = list_price;

  record.unit_price_w_tax = sale_price_w_tax;
  storeRec.unit_price_w_tax = sale_price_w_tax;

  const product = products[0];
  record.product = { id: product, name: name };
  storeRec.product = product;
};

const withChangeDate = (record, store) => {
  const { arrival_date, departure_date, unit_price_w_tax } = record;
  if (arrival_date && departure_date) {
    const nights = getNights(arrival_date, departure_date);
    record.nights_quantity = nights;
    store.nights_quantity = nights;
    if (unit_price_w_tax) {
      record.total_amount = (nights * unit_price_w_tax).toFixed(2);
    }
  }
};

function getNights(arrival, departure) {
  let arrival_date = arrival;
  let departure_date = departure;
  if (typeof arrival === "string") {
    arrival_date = dates.getTrytonDate2Js(arrival);
  }
  if (typeof departure_date === "string") {
    departure_date = dates.getTrytonDate2Js(departure);
  }
  return date.subtract(departure_date, arrival_date).toDays();
}

const getFilters = () => {
  const session = store.get("ctxSession");
  return {
    today: `[
      ('sale_date', '=', '${today}'),
      ('create_uid', '=', ${session.user}),
    ]`,
  };
};

function checkVisible(name, record) {
  const res = false;
  if (record) {
    if (name === "check_in" && record.registration_state === "pending") {
      return true;
    }
    if (name === "check_out" && record.registration_state === "check_in") {
      return true;
    }
  }
  return res;
}

function confirmVisible(name, record) {
  if (!record || !record.number) {
    return true;
  }
  return false;
}

async function confirmMethod() {
  const { store: folio, reload, setCtx } = useWizardStore.getState();
  const _ctxView = getView();
  setCtx({ ctxView: _ctxView, model: "hotel.folio" });
  const _folio = funcs.recToTryton({ ...folio });
  let channel;
  if (folio.channel) {
    channel = folio.channel;
  }
  const contact = _folio.contact;
  delete _folio.total_amount;
  delete _folio.contact;
  delete _folio.channel;
  let state = "draft";
  const propertyOwner = store.get("owner");
  if (propertyOwner) {
    _folio.registration_state = "blocked";
    state = "blocked";
  }

  const storeRec = {
    bill_to: "holder",
    channel: channel,
    contact: contact,
    state: state,
    media: "other",
    lines: [["create", [_folio]]],
  };
  const { data, error } = await proxy.saveQuery({
    model: "hotel.booking",
    storeRec: storeRec,
  });
  // await reload();
}

function chargesVisible(record) {
  if (!record || !record.number) {
    return false;
  }
  return true;
}

const getView = () => {
  let DictCtxView = {
    model: "hotel.folio",
    row_selectable: false,
    form_action: ["edit"], // options: ['edit', 'delete']
    table_action: [], // options: ['open', 'delete', 'edit', 'add']
    activeSearch: true,
    form_rec_name: getName,
    filters: getFilters,
    title: { field: "booking", component: "title" },
    webfields: {
      registration_card: { type: "char", readOnly: true },
      main_guest: {
        type: "many2one",
        model: "party.party",
        readOnly: true,
      },
      booking: {
        type: "many2one",
        model: "hotel.booking",
        readOnly: true,
        attrs: ["agent.rec_name", "number"],
      },
      arrival_date: {
        type: "date",
        required: true,
        withChange: withChangeDate,
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
      },
      departure_date: {
        type: "date",
        required: true,
        withChange: withChangeDate,
        readOnly: {
          registration_state: ["check_out"],
        },
      },
      contact: {
        type: "char",
        searchable: true,
        required: true,
      },
      unit_price: {
        type: "numeric",
        required: true,
        readOnly: {
          registration_state: ["check_out", "check_in"],
        },
      },
      pending_total: { type: "numeric", readOnly: true },
      nights_quantity: {
        type: "integer",
        readOnly: true,
      },
      agent: {
        type: "many2one",
        model: "commission.agent",
        function: getAgent,
        readOnly: true,
      },
      room: {
        type: "many2one",
        model: "hotel.room",
        required: true,
        recSearch: searchRooms,
        withChange: onChangeRoom,
        readOnly: {
          registration_state: ["check_out", "check_in"],
        },
        attrs: [
          "main_accommodation.list_price",
          "main_accommodation.sale_price_w_tax",
          "main_accommodation.name",
          "main_accommodation.products",
        ],
      },
      product: {
        type: "many2one",
        model: "product.product",
        recSearch: searchProduct,
        readOnly: true,
      },
      channel: {
        type: "many2one",
        model: "hotel.channel",
        readOnly: readOnlyChannel,
        recSearch: searchChannel,
        images: { targetField: "code", source: {} },
      },
      registration_state: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      meal_plan: {
        type: "many2one",
        model: "hotel.meal_plan",
        readOnly: true,
      },
      unit_price_w_tax: {
        type: "numeric",
        readOnly: true,
      },
      guests: {
        type: "one2many",
        model: "hotel.folio.guest",
        ctxView: modelGuest.ctxView(),
        readOnly: true,
        visible: chargesVisible,
      },
      charges: {
        type: "one2many",
        model: "hotel.folio.charge",
        ctxView: modelCharge.ctxView(),
        readOnly: true,
        visible: chargesVisible,
      },
      total_amount: { type: "numeric", readOnly: true },
      notes: {
        type: "text",
        readOnly: false,
      },
      vehicle_plate: {
        type: "char",
        readOnly: ["check_out"],
      },
      group: {
        type: "boolean",
        readOnly: {
          registration_state: ["check_out", "check_in"],
        },
        translate: true,
      },
      payment_status: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      check_in: {
        type: "button",
        button_method: "check_in",
        visible: checkVisible,
        onSuccessMsg: "Check In exitoso!",
        color: "blue",
      },
      check_out: {
        type: "button",
        button_method: "check_out",
        visible: checkVisible,
        onSuccessMsg: "Check Out exitoso!",
        color: "blue",
      },
      confirm: {
        type: "button",
        method: confirmMethod,
        visible: confirmVisible,
        // onSuccessMsg: "Confirmación exitoso!",
        color: "green",
        close: true,
      },
    },
    webtree: [
      { name: "registration_card", width: "15%" },
      { name: "room", width: "15%" },
      { name: "main_guest", width: "15%" },
      { name: "product", width: "15%" },
      { name: "arrival_date", width: "10%" },
      { name: "departure_date", width: "10%" },
      { name: "nights_quantity", width: "10%" },
      { name: "registration_state", width: "10%" },
    ],
    webform: [
      { name: "product" },
      {
        id: "booking",
        grid: [{ name: "booking" }, { name: "registration_card" }],
        size: [1, 2],
      },

      { name: "room" },
      { name: "main_guest" },
      { name: "contact" },
      {
        id: "dates",
        grid: [{ name: "arrival_date" }, { name: "departure_date" }],
        size: [1, 2],
        // border: "visible",
        span: "md:col-span-1",
      },
      {
        id: "amounts",
        grid: [
          { name: "nights_quantity" },
          { name: "unit_price" },
          { name: "unit_price_w_tax" },
          { name: "total_amount" },
        ],
        span: "md:col-span-2",
        size: [1, 4],
      },
      {
        id: "sales",
        grid: [{ name: "channel" }, { name: "meal_plan" }, { name: "group" }],
        size: [1, 3],
        span: "md:col-span-2",
      },
      { name: "agent" },
      { name: "pending_total" },
      { name: "registration_state" },
      { name: "payment_status" },
      { name: "notes" },
      {
        id: "more_info",
        grid: [
          {
            name: "guests",
            widget: "button_modal",
            color: "sky",
            icon: "fi fi-rr-users",
          },
          {
            name: "charges",
            widget: "button_modal",
            color: "amber",
            icon: "fi fi-rr-file-invoice",
          },
        ],
        size: [1, 2],
        span: "md:col-span-2",
      },
      { name: "check_out" },
      { name: "confirm" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
