import React from "react";

const ItemPerson = ({ item, handlerOpenModal }) => {
  const party = item["party."];
  const { name, id_number } = party;

  return (
    <div
      onClick={() => handlerOpenModal(item)}
      className="cursor-pointer active:bg-gray-500 active:text-white hover:bg-gray-500 hover:text-white px-4 py-2"
    >
      <div className="flex space-x-3 text-xl pt-2">
        <img
          src={`https://ui-avatars.com/api/?name=${name}&background=0D8ABC&color=fff`}
          className="w-10 h-10 rounded-full"
          alt={`Avatar of ${name}`}
        />
        <div className="flex flex-col">
          <div className="flex flex-col">
            <span className="font-semibold uppercase">Nombre</span>
            <span className="text-base">{name}</span>
          </div>
          <div className="flex flex-col">
            <span className="font-semibold uppercase">Cedula</span>
            <span className="text-base">{id_number}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemPerson;
