import despegarImg from "assets/apps/despegar.png";
import bookingImg from "assets/apps/booking.png";
import expediaImg from "assets/apps/expedia.png";
import houseImg from "assets/apps/house.png";

export const channelsImg = {
  booking: bookingImg,
  despegar: despegarImg,
  expedia: expediaImg,
  house: houseImg,
};

export const tagsColor = {
  none: "bg-zinc-300 border-gray-400",
  blocked: "bg-rose-300 border-rose-400",
  check_in: "bg-emerald-300 border-emerald-400",
  check_out: "bg-sky-200 border-sky-300",
  pending: "bg-amber-200 border-amber-400",
  maintenance: "bg-gray-500 border-gray-800",
};

export const tagsColorText = {
  none: "text-zinc-800",
  blocked: "text-rose-800",
  check_in: "text-lime-800",
  check_out: "text-sky-800",
  pending: "text-amber-700",
  maintenance: "text-gray-100",
};

export const borderColor = {
  none: "border-zinc-600",
  blocked: "border-rose-600",
  check_in: "border-lime-500",
  check_out: "border-sky-400",
  pending: "border-amber-300",
  maintenance: "border-rose-500",
};

export const tableStyle = {
  border: "border-[1px]",
  headerRow: "bg-zinc-600",
  cellStyle: "border-y-[1px] border-zinc-200",
  roomRow: "border-y-[1px] border-sky-600",
  categoryStyle: "bg-zinc-700 ",
};
