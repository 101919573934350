import React from "react";

import Divider from "components/MeForm/Divider";
import CardSectionRow from "./CardSectionRow";
import CardTagData from "./CardTagData";
import { classNames } from "tools/ui";

const base =
  "relative shadow-lg rounded-lg px-4 bg-white py-4 h-44 border border-zinc-200";
const zoneClickable = "active:scale-95 active:bg-slate-100 active:px-3";

function InfoCard({
  children,
  header,
  header_meta,
  desc,
  desc_meta,
  prev_value,
  rate_prev_value,
  getReport,
  style,
}) {
  function onClick() {
    getReport();
  }
  console.log("header....", header);
  console.log("prev_value....", prev_value);
  console.log("rate_prev_value....", rate_prev_value);

  return (
    <div id="chart-card" className={classNames(base, style)}>
      <div className="flex justify-between min-w-52">
        {prev_value != undefined && (
          <CardTagData value={prev_value} kind="money" color="amber" />
        )}
        {rate_prev_value != undefined && (
          <CardTagData value={rate_prev_value} kind="rate" color="sky" />
        )}
      </div>
      {children}
      {getReport && (
        <div className="absolute inset-x-0 bottom-2 px-3 mt-auto">
          <div className={classNames(zoneClickable)} onClick={onClick}>
            <Divider />
            <CardSectionRow
              content1={header}
              content2={header_meta}
              style="text-slate-800"
            />
            {desc && (
              <CardSectionRow
                style="text-blue-400"
                content1={desc}
                content2={desc_meta}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default InfoCard;
