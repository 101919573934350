// Opportunity model line

const BILLING = [
  { id: "one_payment", name: "one_payment" },
  { id: "monthly", name: "monthly" },
  { id: "bimonthly", name: "bimonthly" },
  { id: "biannual", name: "biannual" },
  { id: "annual", name: "annual" },
];

function getProduct(data) {
  return [
    ["active", "=", true],
    ["template.salable", "=", true],
    ["template.account_category", "!=", null],
    ["template.name", "ilike", `%${data}%`],
  ];
}

const withChangeProduct = (record, store) => {
  if (record.product) {
    const product = record.product;
    let uom = product["sale_uom."];
    let unit_price = product.sale_price_taxed;
    unit_price = parseFloat(unit_price?.toString() || 0);
    store.unit_price = unit_price;
    store.unit = uom.id;
    record.unit_price = unit_price;
    record.unit_price_w_tax = record.product.sale_price_taxed;
    record.unit = uom;
  }
};

const getView = () => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    model: "crm.opportunity.line",
    form_action: ["add", "edit"],
    table_action: ["add", "edit"],
    validate_state: true,
    webfields: {
      product: {
        type: "many2one",
        model: "product.product",
        withChange: withChangeProduct,
        recSearch: getProduct,
        required: true,
        attrs: [
          "id",
          "list_price",
          "name",
          // "sale_price_taxed",
          "sale_uom.rec_name",
        ],
      },
      unit: {
        type: "many2one",
        model: "product.uom",
        readOnly: true,
      },
      quantity: { type: "integer", default: 1, required: true },
      availability: { type: "char" },
      unit_price: { type: "numeric", required: true },
      description: { type: "text" },
      payment_term: {
        type: "many2one",
        model: "account.invoice.payment_term",
        recSearch: () => "[]",
      },
      billing_frecuency: {
        type: "selection",
        options: BILLING,
        translate: true,
      },
    },
    webtree: [
      { name: "product", width: "30%" },
      { name: "quantity", width: "10%" },
      { name: "unit", width: "5%" },
      { name: "unit_price", width: "10%" },
      { name: "description", width: "35%" },
      { name: "billing_frecuency", width: "10%" },
    ],
    webform: [
      { name: "product" },
      { name: "unit" },
      { name: "quantity" },
      { name: "unit_price" },
      // { name: "availability" },
      // { name: "billing_frecuency" },
      // { name: "payment_term" },
      { name: "description", colspan: 2 },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
